<script setup lang="ts">
import { ref, watch, computed, onMounted } from "vue";
import * as pressable from "@zag-js/pressable";
import { normalizeProps, useMachine } from "@zag-js/vue";
import { SvgIcon } from "../";

defineExpose({ setFocus });
const emit = defineEmits(["search", "submit", "focus"]);
interface Props {
	placeholder: string;
	live?: boolean;
	focus?: boolean;
}

const { live = false, focus = false, placeholder } = defineProps<Props>();

const [state, send] = useMachine(
	pressable.machine({
		id: (Math.random() + 1).toString(36).substring(7),
		onPress: () => emit("search", search.value)
	})
);

const api = computed(() => pressable.connect(state.value, send, normalizeProps));

const search = ref<string>("");
const input = ref<HTMLInputElement>();

onMounted(() => {
	if (focus) setFocus();
});

if (live) {
	watch(
		() => search.value,
		(value: string) => emit("search", value)
	);
}

function setFocus(): void {
	if (input.value) input.value.focus();
}
</script>

<template>
	<div role="search" class="text-sm w-full relative">
		<input
			ref="input"
			v-model="search"
			type="search"
			aria-label="Search input"
			:placeholder="placeholder"
			class="bg-white rounded-full overflow-hidden border border-brown pl-6 pr-10 py-[10px] w-full"
			@keydown.enter="$emit('submit', search)"
			@focus="$emit('focus')"
		/>
		<button v-bind="api.pressableProps" class="min-w-[1rem] absolute right-4 top-1/2 -translate-y-1/2">
			<SvgIcon name="search" />
		</button>
	</div>
</template>
